/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppProjectGranularPermission = typeof AppProjectGranularPermission[keyof typeof AppProjectGranularPermission];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppProjectGranularPermission = {
  'view-project': 'view-project',
  'archive-project': 'archive-project',
  'view-project-metadata': 'view-project-metadata',
  'edit-project-metadata': 'edit-project-metadata',
  'view-run-inputs': 'view-run-inputs',
  'view-runs': 'view-runs',
  'clone-run': 'clone-run',
  'edit-run-inputs': 'edit-run-inputs',
  'archive-run': 'archive-run',
  'start-run': 'start-run',
  'accept-run': 'accept-run',
  'edit-run-qa-rfs': 'edit-run-qa-rfs',
  'edit-run-qa-npws': 'edit-run-qa-npws',
  'generate-comparison-of-risk': 'generate-comparison-of-risk',
  'generate-firespread-visualisation': 'generate-firespread-visualisation',
  'publish-project': 'publish-project',
  'query-outputs': 'query-outputs',
  'view-outputs-ignitions': 'view-outputs-ignitions',
  'view-outputs-fire-behaviour': 'view-outputs-fire-behaviour',
  'view-outputs-regular': 'view-outputs-regular',
  'view-outputs-npws': 'view-outputs-npws',
  'view-outputs-npws-aggregated': 'view-outputs-npws-aggregated',
  'view-outputs-change-in-risk': 'view-outputs-change-in-risk',
  'view-outputs-aggregated': 'view-outputs-aggregated',
  'download-outputs-ignitions': 'download-outputs-ignitions',
  'download-outputs-fire-behaviour': 'download-outputs-fire-behaviour',
  'download-outputs-aggregated': 'download-outputs-aggregated',
  'download-outputs-regular': 'download-outputs-regular',
  'download-outputs-npws': 'download-outputs-npws',
  'download-outputs-npws-aggregated': 'download-outputs-npws-aggregated',
  'download-outputs-change-in-risk': 'download-outputs-change-in-risk',
  'change-project-owner': 'change-project-owner',
  'add-project-collaborator': 'add-project-collaborator',
  'add-project-viewer': 'add-project-viewer',
  'can-be-owner': 'can-be-owner',
  'can-be-collaborator': 'can-be-collaborator',
  'can-be-viewer': 'can-be-viewer',
  'view-project-permissions': 'view-project-permissions',
  'approve-project-individual-treatment-comparison-brigade': 'approve-project-individual-treatment-comparison-brigade',
  'reject-project-individual-treatment-comparison-brigade': 'reject-project-individual-treatment-comparison-brigade',
  'view-area-of-interest': 'view-area-of-interest',
  'view-project-overview-page': 'view-project-overview-page',
  'view-project-outputs-geospatial-page': 'view-project-outputs-geospatial-page',
  'view-project-outputs-download-page': 'view-project-outputs-download-page',
  'view-configuration-page': 'view-configuration-page',
  'view-project-outputs-comparison-page': 'view-project-outputs-comparison-page',
  'view-project-outputs-numeric-page': 'view-project-outputs-numeric-page',
  'view-inputs-general-page': 'view-inputs-general-page',
  'view-inputs-fire-behaviour-page': 'view-inputs-fire-behaviour-page',
  'view-inputs-impact-analysis-page': 'view-inputs-impact-analysis-page',
  'download-configuration-values': 'download-configuration-values',
  'update-configuration-values': 'update-configuration-values',
} as const;
