/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppStatewideMaxFuelsScenario = typeof AppStatewideMaxFuelsScenario[keyof typeof AppStatewideMaxFuelsScenario];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppStatewideMaxFuelsScenario = {
  MS: 'MS',
} as const;
