/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppWeatherDataType = typeof AppWeatherDataType[keyof typeof AppWeatherDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppWeatherDataType = {
  netcdf: 'netcdf',
  point: 'point',
} as const;
