/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type ServiceInputType = typeof ServiceInputType[keyof typeof ServiceInputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceInputType = {
  asset: 'asset',
  'asset-npws': 'asset-npws',
  assets: 'assets',
  'asset-costs': 'asset-costs',
  'asset-types-npws': 'asset-types-npws',
  boundary: 'boundary',
  classifications: 'classifications',
  dem: 'dem',
  disruptions: 'disruptions',
  'fdr-probabilities': 'fdr-probabilities',
  'fire-history': 'fire-history',
  fuel: 'fuel',
  'fuel-management-register': 'fuel-management-register',
  'fuel-type': 'fuel-type',
  'grass-curing': 'grass-curing',
  ignitions: 'ignitions',
  'modified-fire-history': 'modified-fire-history',
  'phoenix-settings': 'phoenix-settings',
  'postprocessing-settings': 'postprocessing-settings',
  projection: 'projection',
  'road-proximity': 'road-proximity',
  'supplemental-wildfire-history': 'supplemental-wildfire-history',
  suppression: 'suppression',
  'user-layer': 'user-layer',
  vegetation: 'vegetation',
  'vulnerability-curves': 'vulnerability-curves',
  weather: 'weather',
  'weather-data': 'weather-data',
  'wind-modifiers': 'wind-modifiers',
} as const;
