/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppModelledImpactTimeProjectAllOfType = typeof AppModelledImpactTimeProjectAllOfType[keyof typeof AppModelledImpactTimeProjectAllOfType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppModelledImpactTimeProjectAllOfType = {
  'modelled-impact-time': 'modelled-impact-time',
} as const;
