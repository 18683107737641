/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppAuditRunArchivedEventType = typeof AppAuditRunArchivedEventType[keyof typeof AppAuditRunArchivedEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAuditRunArchivedEventType = {
  'run-archived': 'run-archived',
} as const;
