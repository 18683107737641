/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppAnnualWorksPlanEvaluationScenario = typeof AppAnnualWorksPlanEvaluationScenario[keyof typeof AppAnnualWorksPlanEvaluationScenario];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAnnualWorksPlanEvaluationScenario = {
  PM: 'PM',
} as const;
