/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */
import { AppProjectGranularPermission } from './appProjectGranularPermission';
import { AppGeneralPermission } from './appGeneralPermission';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppPermission = {...AppProjectGranularPermission,...AppGeneralPermission,} as const
export type AppPermission = typeof AppPermission[keyof typeof AppPermission] ;
