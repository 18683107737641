/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type ServiceProjectTypeAttribute = typeof ServiceProjectTypeAttribute[keyof typeof ServiceProjectTypeAttribute];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceProjectTypeAttribute = {
  bfrmp: 'bfrmp',
  'statewide-maxfuels': 'statewide-maxfuels',
  'statewide-snapshot': 'statewide-snapshot',
  'modelled-impact-time': 'modelled-impact-time',
  'individual-treatment-comparison': 'individual-treatment-comparison',
  'individual-treatment-comparison-brigade': 'individual-treatment-comparison-brigade',
  'annual-works-plan-forecast': 'annual-works-plan-forecast',
  'annual-works-plan-evaluation': 'annual-works-plan-evaluation',
} as const;
